// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
// scss-docs-end container-max-widths

// Colors
$primary: #6200ee;
$primary-hover: #3700b3;
$primary-focus: #3700b3;
$primary-active: #1a00a7;

$secondary: #03dac6;
$secondary-hover: #018786;
$secondary-focus: #018786;
$secondary-active: #005f56;

$success: #4caf50;
$info: #2196f3;
$warning: #fb8c00;
$danger: #f44336;

$light: #f5f5f5;
$dark: #121212;

$body-bg: #ffffff;
$body-color: #000000;

$surface: #ffffff;
$background: #f5f5f5;
$on-surface: #000000;

// Typography
$font-family-sans-serif: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$font-family-monospace: 'Roboto Mono', monospace;
$font-size-base: 1rem;
$font-weight-base: 400;
$line-height-base: 1.5;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

// Buttons
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-border-radius: 0.25rem;
$btn-font-weight: 500;
$btn-primary-bg: $primary;
$btn-primary-color: #ffffff;
$btn-primary-border: transparent;
$btn-secondary-bg: $secondary;
$btn-secondary-color: #000000;
$btn-secondary-border: transparent;

// Shadows (Elevation)
$box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.12);
$box-shadow-lg: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);

// Borders
$border-radius: 0.25rem;
$border-color: #e0e0e0;
$border-width: 1px;
$border-style: solid;

// Cards
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-border-radius: 0.25rem;
$card-border-width: 1px;
$card-border-color: $border-color;
$card-bg: $surface;
$card-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

// Forms
$form-label-margin-bottom: 0.5rem;
$form-text-margin-top: 0.25rem;
$input-padding-y: 0.5rem;
$input-padding-x: 1rem;
$input-border-radius: 0.25rem;
$input-border-color: $border-color;
$input-bg: $surface;
$input-color: $on-surface;

// Navbars
$navbar-light-color: $dark;
$navbar-light-bg: $surface;
$navbar-dark-color: $light;
$navbar-dark-bg: $dark;

// Alerts
$alert-padding-y: 1rem;
$alert-padding-x: 1rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: 0.25rem;
$alert-border-width: $border-width;
$alert-border-color: $border-color;
$alert-bg-level-1: rgba($primary, 0.1);
$alert-bg-level-2: rgba($secondary, 0.1);
$alert-color: $on-surface;
