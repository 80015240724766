
// Bootstrap
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";

// Font Awesome
//$fa-font-path: "../fonts"; // Define o caminho correto para as fontes
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";


// Vegas.JS
@import "../node_modules/vegas/src/sass/vegas";

// Animate CSS
@import "../node_modules/animate.css/animate.css";